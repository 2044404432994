import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import Breadcrumb from "@components/Breadcrumb";
import { DeepNonNullable } from "ts-essentials";
import { AllHelpArtcitlesCategoriesDataQuery } from "../../graphqlTypes";
import BannerSection from "@components/BannerSection";
import HelpCard from "@ui/HelpCard";
import SVGLines from "@ui/SVGVerticalSearchLines";
import HelpSearch from "@components/HelpSearch";

export const HelpCategories = graphql`
  query AllHelpArtcitlesCategoriesData {
    strapiHelp {
      pageTitle
      title
      description
      searchTitle
      searchPlaceholder
    }
    allStrapiHelpCategory {
      edges {
        node {
          slug
          id
          description
          name
        }
      }
    }
  }
`;

const HelpPage = ({
  data,
}: {
  data: DeepNonNullable<AllHelpArtcitlesCategoriesDataQuery>;
}) => {
  const { edges } = data.allStrapiHelpCategory;
  const { pageTitle, title, description } = data.strapiHelp;

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block flex flex-col max-w-7xl mx-5 md:mx-auto mt-4 mb-4">
        <Breadcrumb title="Help" />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <p className="text-4xl text-white font-semibold mt-16">{title}</p>
            <p className="text-white mb-16">{description}</p>
          </div>
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="div-block max-w-fit md:max-w-7xl bg-accuRankerCream w-full pb-12 mx-5 md:m-auto px-0 sm:px-0">
          <div className="max-w-7xl flex flex-col gap-11 m-auto sm:px-0">
            <div>
              <div className="relative z-10">
                <SVGLines />
                <HelpSearch />
                <div className="w-full flex flex-col gap-8">
                  {edges.map(edge => {
                    const {
                      node: { description, slug, name },
                    } = edge;
                    return (
                      <HelpCard
                        title={name}
                        subtitle={description}
                        categorySlug={slug}
                      />
                    );
                  })}
                </div>
              </div>
              {/*<BannerSection />*/}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default HelpPage;
